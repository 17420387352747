var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "组织基础信息",
                  "head-btn-options": _vm.headTopBtnOptions,
                },
                on: { "head-goback": _vm.handleHeadGoback },
              }),
              _c("form-layout", {
                ref: "formLayout",
                attrs: { column: _vm.formColumn, dataForm: _vm.nodeData },
                on: {
                  "update:dataForm": function ($event) {
                    _vm.nodeData = $event
                  },
                  "update:data-form": function ($event) {
                    _vm.nodeData = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "证件",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: { "head-upload": _vm.handleUpload },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  editType: _vm.inline,
                },
                on: {
                  "row-view": _vm.rowView,
                  "row-download": _vm.rowDownload,
                  "row-edit": _vm.rowEdit,
                  "row-remove": _vm.rowRemove,
                  "row-setting": _vm.rowSetting,
                },
                scopedSlots: _vm._u([
                  {
                    key: "fileName",
                    fn: function ({ row, index }) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.rowView(row, index)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.getFileName(row.fileName)))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "设置提醒", visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.remaindForm, "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "提前提醒天数" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1 },
                    model: {
                      value: _vm.remaindForm.dateRemind,
                      callback: function ($$v) {
                        _vm.$set(_vm.remaindForm, "dateRemind", $$v)
                      },
                      expression: "remaindForm.dateRemind",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "通知人" } },
                [
                  _c("el-input", {
                    ref: "remaindFormUser",
                    on: { focus: _vm.changeUser },
                    model: {
                      value: _vm.remaindForm.noticeUserName,
                      callback: function ($$v) {
                        _vm.$set(_vm.remaindForm, "noticeUserName", $$v)
                      },
                      expression: "remaindForm.noticeUserName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "证件查看", visible: _vm.imageDiavlog },
          on: {
            "update:visible": function ($event) {
              _vm.imageDiavlog = $event
            },
          },
        },
        [
          _vm.fileUrl.type == "pdf"
            ? _c("pdf", { attrs: { src: _vm.fileUrl.src } })
            : _c("el-image", { attrs: { src: _vm.fileUrl.src } }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.userVisible,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _c("cedForm", { ref: "cedForm", on: { callback: _vm.callback } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }